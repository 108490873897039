import * as tslib_1 from "tslib";
import { FormGroup, FormControl, Validators } from '@angular/forms';
var SignInForm = /** @class */ (function (_super) {
    tslib_1.__extends(SignInForm, _super);
    function SignInForm() {
        return _super.call(this, {
            email: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        }) || this;
    }
    Object.defineProperty(SignInForm.prototype, "email", {
        get: function () {
            return this.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInForm.prototype, "emailErrorMessage", {
        get: function () {
            return this.email.touched && this.email.hasError('required')
                ? 'Email requis'
                : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInForm.prototype, "password", {
        get: function () {
            return this.get('password');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInForm.prototype, "passwordErrorMessage", {
        get: function () {
            return this.password.touched && this.password.hasError('required')
                ? 'Mot de passe requis'
                : null;
        },
        enumerable: true,
        configurable: true
    });
    return SignInForm;
}(FormGroup));
export { SignInForm };
