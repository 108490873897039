import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { TiimeAuthService } from 'tiime-auth';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-material';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';
import { SignInForm } from './signin-form';
import { AUTH } from '../../constants/auth.constants';
import { POST_SIGN_IN_TARGET_PARAM_NAME } from '../auth-callback/auth-callback.component';
var SigninComponent = /** @class */ (function () {
    function SigninComponent(authService, queryParamsMap$, snackbar, windowRef) {
        this.authService = authService;
        this.queryParamsMap$ = queryParamsMap$;
        this.snackbar = snackbar;
        this.windowRef = windowRef;
        this.signInForm = new SignInForm();
        this.tiimePulseUrl = AUTH.tiimePulseWebsiteUrl;
    }
    SigninComponent.prototype.ngOnInit = function () {
        this.initPostSignInTargetPath();
    };
    SigninComponent.prototype.submitSignInForm = function () {
        this.login();
    };
    SigninComponent.prototype.clearPostSignInTargetQueryParamFromURL = function () {
        var queryParams = new URLSearchParams(this.windowRef.location.search);
        queryParams.delete(POST_SIGN_IN_TARGET_PARAM_NAME);
        var queryParamsString = queryParams.toString();
        var urlSearch = queryParamsString ? "?" + queryParamsString : '';
        this.windowRef.history.replaceState({}, '', "" + this.windowRef.location.pathname + urlSearch);
    };
    SigninComponent.prototype.getLoginObservable = function () {
        var email = this.signInForm.email.value;
        var password = this.signInForm.password.value;
        var promiseToLogin = this.postSignInTargetPath
            ? this.authService.loginAndRedirect(email, password, this.windowRef.location.origin + "/auth-callback?" + POST_SIGN_IN_TARGET_PARAM_NAME + "=" + this.postSignInTargetPath)
            : this.authService.login(email, password);
        return from(promiseToLogin);
    };
    SigninComponent.prototype.initPostSignInTargetPath = function () {
        var _this = this;
        this.queryParamsMap$
            .pipe(map(function (queryParams) {
            return queryParams.get(POST_SIGN_IN_TARGET_PARAM_NAME);
        }), filter(function (postSignInTargetPath) { return !!postSignInTargetPath; }), tap(function (postSignInTargetPath) {
            return (_this.postSignInTargetPath = postSignInTargetPath);
        }), tap(function () { return _this.clearPostSignInTargetQueryParamFromURL(); }))
            .subscribe();
    };
    SigninComponent.prototype.login = function () {
        var _this = this;
        this.getLoginObservable()
            .pipe(catchError(function () {
            _this.loginError();
            return EMPTY;
        }))
            .subscribe();
    };
    SigninComponent.prototype.loginError = function () {
        this.snackbar.open('Identifiant ou mot de passe invalide', SnackbarConfig.error);
    };
    tslib_1.__decorate([
        MarkFormAsTouchedIfInvalid('signInForm'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], SigninComponent.prototype, "submitSignInForm", null);
    return SigninComponent;
}());
export { SigninComponent };
