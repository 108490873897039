var BusinessUserSlugComponent = /** @class */ (function () {
    function BusinessUserSlugComponent() {
        this.fontSize = '14px';
        this.size = '40px';
    }
    Object.defineProperty(BusinessUserSlugComponent.prototype, "setFontSize", {
        set: function (fontSize) {
            if (fontSize) {
                this.fontSize = fontSize;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusinessUserSlugComponent.prototype, "setSize", {
        set: function (size) {
            if (size) {
                this.size = size;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BusinessUserSlugComponent.prototype, "businessUserSlugStyle", {
        get: function () {
            return {
                background: this.businessUserColor,
                width: this.size,
                height: this.size,
                'line-height': this.size,
                'border-radius': this.size,
                'font-size': this.fontSize
            };
        },
        enumerable: true,
        configurable: true
    });
    return BusinessUserSlugComponent;
}());
export { BusinessUserSlugComponent };
