import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { TiimeAuthService } from 'tiime-auth';
import { POST_SIGN_IN_TARGET_PARAM_NAME } from '../auth/auth-callback/auth-callback.component';
import * as i0 from "@angular/core";
import * as i1 from "tiime-auth";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AuthGuard.prototype.canLoad = function () {
        var _a;
        var isAuthenticated = this.authService.isAuthenticated();
        if (!isAuthenticated) {
            var postSignInTargetPath = this.getPostSignInTargetPathFromNavigation();
            this.router.navigate(['signin'], tslib_1.__assign({}, (postSignInTargetPath && {
                queryParams: (_a = {},
                    _a[POST_SIGN_IN_TARGET_PARAM_NAME] = postSignInTargetPath,
                    _a)
            })));
        }
        return isAuthenticated;
    };
    AuthGuard.prototype.getPostSignInTargetPathFromNavigation = function () {
        var navigation = this.router.getCurrentNavigation();
        if (navigation) {
            var path = navigation.extractedUrl.toString();
            return path && path !== '/' ? path : null;
        }
        return null;
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.TiimeAuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
