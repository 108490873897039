import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { withLatestFrom, tap } from 'rxjs/operators';
import * as BusinessUserActions from './business-user-actions';
import { businessUserSelector } from './business-user-selector';
import { SentryService } from '../../sentry/sentry.service';
var BusinessUserEffects = /** @class */ (function () {
    function BusinessUserEffects(store, actions$, sentryService) {
        var _this = this;
        this.store = store;
        this.actions$ = actions$;
        this.sentryService = sentryService;
        this.updateAction$ = this.actions$.pipe(ofType(BusinessUserActions.UPDATE), withLatestFrom(this.store.select(businessUserSelector)), tap(function (_a) {
            var action = _a[0], businessUser = _a[1];
            return _this.sentryService.setUserFromBusinessUser(businessUser);
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], BusinessUserEffects.prototype, "updateAction$", void 0);
    return BusinessUserEffects;
}());
export { BusinessUserEffects };
