import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { TiimeSnackbarService, SnackbarConfig } from 'tiime-material';
import { isBusinessAgentSelector } from '../store/business-user/business-user-selector';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "tiime-material";
import * as i3 from "@ngrx/store";
var BusinessAgentGuard = /** @class */ (function () {
    function BusinessAgentGuard(router, snackbar, store) {
        this.router = router;
        this.snackbar = snackbar;
        this.store = store;
    }
    BusinessAgentGuard.prototype.canActivate = function () {
        return this.isAgent();
    };
    BusinessAgentGuard.prototype.canLoad = function () {
        return this.isAgent();
    };
    BusinessAgentGuard.prototype.isAgent = function () {
        var _this = this;
        return this.store.pipe(select(isBusinessAgentSelector), take(1), tap(function (isBusinessAgent) {
            if (!isBusinessAgent) {
                _this.snackbar.open("Acc\u00E8s refus\u00E9", SnackbarConfig.error);
                _this.router.navigate(['/signin']);
            }
        }));
    };
    BusinessAgentGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessAgentGuard_Factory() { return new BusinessAgentGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.TiimeSnackbarService), i0.ɵɵinject(i3.Store)); }, token: BusinessAgentGuard, providedIn: "root" });
    return BusinessAgentGuard;
}());
export { BusinessAgentGuard };
