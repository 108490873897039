import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var SentryService = /** @class */ (function () {
    function SentryService() {
    }
    SentryService.prototype.captureException = function (error) {
        if (environment.SENTRY_DNS) {
            Sentry.captureException(error.originalError || error);
        }
        else {
            console.error(error);
        }
    };
    SentryService.prototype.cleanUser = function () {
        if (environment.SENTRY_DNS) {
            Sentry.configureScope(function (scope) { return scope.setUser(null); });
        }
    };
    SentryService.prototype.init = function () {
        if (environment.SENTRY_DNS) {
            Sentry.init({
                dsn: environment.SENTRY_DNS,
                environment: environment.ENV_NAME,
                release: environment.VERSION,
                beforeSend: function (event, hint) {
                    var error = hint.originalException;
                    if (error && error instanceof HttpErrorResponse) {
                        return null;
                    }
                    return event;
                }
            });
        }
    };
    SentryService.prototype.setUserFromBusinessUser = function (businessUser) {
        if (environment.SENTRY_DNS) {
            Sentry.configureScope(function (scope) {
                return scope.setUser({
                    id: String(businessUser.id),
                    username: businessUser.fullName,
                    email: businessUser.email,
                    phone: businessUser.phone
                });
            });
        }
    };
    SentryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentryService_Factory() { return new SentryService(); }, token: SentryService, providedIn: "root" });
    return SentryService;
}());
export { SentryService };
