import * as tslib_1 from "tslib";
import { UserBusinessUnitRole } from './user-business-unit-role';
import { CompanyListItem } from './company-list-item';
import { UserBase } from './user-base';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User(id, firstName, lastName, email, phone, companies, passwordUpdatedAt, businessUnitsRole) {
        var _this = _super.call(this, id, firstName, lastName, email) || this;
        _this.phone = phone;
        _this.companies = companies;
        _this.passwordUpdatedAt = passwordUpdatedAt;
        _this.businessUnitsRole = businessUnitsRole;
        return _this;
    }
    User.fromJson = function (json) {
        return new User(json.id, json.firstname, json.lastname, json.email, json.phone, json.companies
            ? json.companies.map(function (companyJson) {
                return CompanyListItem.fromJson(companyJson);
            })
            : [], json.password_updated_at, json.business_unit_users
            ? json.business_unit_users.map(function (businessUserBusinessUnitRole) {
                return UserBusinessUnitRole.fromJson(businessUserBusinessUnitRole);
            })
            : []);
    };
    User.toJson = function (user) {
        return {
            id: user.id,
            firstname: user.firstName,
            lastname: user.lastName,
            email: user.email,
            phone: user.phone || null
        };
    };
    return User;
}(UserBase));
export { User };
