var UserBase = /** @class */ (function () {
    function UserBase(id, firstName, lastName, email) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
    }
    Object.defineProperty(UserBase.prototype, "fullName", {
        get: function () {
            return this.firstName && this.lastName
                ? this.firstName + " " + this.lastName
                : this.lastName || this.firstName;
        },
        enumerable: true,
        configurable: true
    });
    return UserBase;
}());
export { UserBase };
