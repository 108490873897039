import * as tslib_1 from "tslib";
import { BusinessUnit } from './business-unit';
import { UserBusinessUnitRole } from './user-business-unit-role';
import { UserBase } from './user-base';
import { User } from './user';
var BusinessUser = /** @class */ (function (_super) {
    tslib_1.__extends(BusinessUser, _super);
    function BusinessUser(id, firstName, lastName, email, phone, businessUnits, hasInternalAccess, color, acronym, businessUnitsRole, roles) {
        var _this = _super.call(this, id, firstName, lastName, email) || this;
        _this.phone = phone;
        _this.businessUnits = businessUnits;
        _this.hasInternalAccess = hasInternalAccess;
        _this.color = color;
        _this.acronym = acronym;
        _this.businessUnitsRole = businessUnitsRole;
        _this.roles = roles;
        return _this;
    }
    BusinessUser.fromJson = function (json) {
        return new BusinessUser(json.id, json.firstname, json.lastname, json.email, json.phone, json.business_units
            ? json.business_units.map(function (businessUnit) {
                return BusinessUnit.fromJson(businessUnit);
            })
            : [], json.has_internal_access, json.color, json.acronym, json.business_unit_users
            ? json.business_unit_users.map(function (businessUserBusinessUnitRole) {
                return UserBusinessUnitRole.fromJson(businessUserBusinessUnitRole);
            })
            : [], json.roles);
    };
    BusinessUser.toJson = function (businessUser) {
        return {
            id: businessUser.id,
            firstname: businessUser.firstName,
            lastname: businessUser.lastName,
            email: businessUser.email,
            phone: businessUser.phone,
            business_unit_users: businessUser.businessUnitsRole
                ? businessUser.businessUnitsRole.map(function (businessUnitRole) {
                    return UserBusinessUnitRole.toJson(businessUnitRole);
                })
                : undefined
        };
    };
    BusinessUser.toUser = function (businessUser) {
        var user = new User();
        user.id = businessUser.id;
        user.firstName = businessUser.firstName;
        user.lastName = businessUser.lastName;
        user.email = businessUser.email;
        user.phone = businessUser.phone;
        user.businessUnitsRole = businessUser.businessUnitsRole;
        return user;
    };
    return BusinessUser;
}(UserBase));
export { BusinessUser };
