import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { VatType } from './models/vat-type';
import { ApiAlertError } from './decorators/api-alert-error';
import { VatSystem } from './models/vat_system';
import { ApeCode } from './models/ape_code';
import { BusinessUser } from './models/business-user';
import { IncomeCategory } from './models/income_category';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
    }
    ApiService.prototype.getApeCodes = function () {
        if (!this.apeCodesCache) {
            var url = "api/v1/ape_codes";
            this.apeCodesCache = this.http.get(url).pipe(map(function (apeCodesJson) {
                return apeCodesJson.map(function (apeCodeJson) { return ApeCode.fromJson(apeCodeJson); });
            }), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.apeCodesCache;
    };
    ApiService.prototype.getBusinessUser = function () {
        var url = 'api/v1/pro/business_users/me';
        return this.http
            .get(url)
            .pipe(map(function (businessUserJson) { return BusinessUser.fromJson(businessUserJson); }));
    };
    ApiService.prototype.getIncomeCategories = function () {
        if (!this.incomeCategoriesCache) {
            var url = 'api/v1/income_categories';
            this.incomeCategoriesCache = this.http.get(url).pipe(map(function (incomeCategoriesJson) {
                return incomeCategoriesJson.map(function (incomeCategoryJson) {
                    return IncomeCategory.fromJson(incomeCategoryJson);
                });
            }), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.incomeCategoriesCache;
    };
    ApiService.prototype.getInvoicingVatTypes = function () {
        if (!this.invoicingVatTypesCache) {
            var url = "api/v1/pro/invoicing_config/vat_types";
            this.invoicingVatTypesCache = this.http.get(url).pipe(map(function (vatTypesJson) {
                return vatTypesJson.map(function (vatTypeJson) { return VatType.fromJson(vatTypeJson); });
            }), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.invoicingVatTypesCache;
    };
    ApiService.prototype.getLegalForms = function () {
        if (!this.legalFormsCache) {
            var url = "api/v1/legal_forms";
            this.legalFormsCache = this.http.get(url).pipe(map(function (legalForms) {
                return legalForms.filter(function (legalForm) { return legalForm !== 'UserApp'; });
            }), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.legalFormsCache;
    };
    ApiService.prototype.getVatSystems = function () {
        if (!this.vatSystemsCache) {
            var url = "api/v1/vat_systems";
            this.vatSystemsCache = this.http.get(url).pipe(map(function (vatSystemsJson) {
                return vatSystemsJson.map(function (vatSystemJson) {
                    return VatSystem.fromJson(vatSystemJson);
                });
            }), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.vatSystemsCache;
    };
    ApiService.prototype.getVatTypes = function () {
        if (!this.vatTypesCache) {
            var url = "api/v1/pro/vat_types";
            this.vatTypesCache = this.http.get(url).pipe(map(function (vatTypesJson) {
                return vatTypesJson.map(function (vatTypeJson) { return VatType.fromJson(vatTypeJson); });
            }), shareReplay({ refCount: true, bufferSize: 1 }));
        }
        return this.vatTypesCache;
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    tslib_1.__decorate([
        ApiAlertError(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], ApiService.prototype, "getApeCodes", null);
    tslib_1.__decorate([
        ApiAlertError(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], ApiService.prototype, "getBusinessUser", null);
    tslib_1.__decorate([
        ApiAlertError(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], ApiService.prototype, "getIncomeCategories", null);
    tslib_1.__decorate([
        ApiAlertError(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], ApiService.prototype, "getInvoicingVatTypes", null);
    tslib_1.__decorate([
        ApiAlertError(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], ApiService.prototype, "getLegalForms", null);
    tslib_1.__decorate([
        ApiAlertError(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], ApiService.prototype, "getVatSystems", null);
    tslib_1.__decorate([
        ApiAlertError(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", Observable)
    ], ApiService.prototype, "getVatTypes", null);
    return ApiService;
}());
export { ApiService };
