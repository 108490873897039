import { VatRate } from './vat-rate';
import { DateHelper } from '../helpers/date.helper';
var VatType = /** @class */ (function () {
    function VatType(code, name, main, area, vatRates, invoiceLegalNotice) {
        this.code = code;
        this.name = name;
        this.main = main;
        this.area = area;
        this.vatRates = vatRates;
        this.invoiceLegalNotice = invoiceLegalNotice;
    }
    Object.defineProperty(VatType.prototype, "hasName", {
        get: function () {
            return this.name !== '{{rate}}';
        },
        enumerable: true,
        configurable: true
    });
    VatType.fromJson = function (json) {
        return new VatType(json.code, json.name, json.main, json.area, json.vat_rates
            ? json.vat_rates.map(function (vatRateJson) { return VatRate.fromJson(vatRateJson); })
            : [], json.invoice_legal_notice);
    };
    VatType.toJson = function (vatType) {
        return {
            code: vatType.code,
            name: vatType.name,
            main: vatType.main,
            area: vatType.area,
            vat_rates: vatType.vatRates
                ? vatType.vatRates.map(function (vatRate) { return VatRate.toJson(vatRate); })
                : undefined,
            invoice_legal_notice: vatType.invoiceLegalNotice
        };
    };
    VatType.prototype.vatRateEmissionDateIn = function (emissionDate) {
        if (!emissionDate) {
            emissionDate = new Date();
        }
        else if (DateHelper.isMoment(emissionDate)) {
            emissionDate = DateHelper.toDate(emissionDate);
        }
        else if (typeof emissionDate === 'string') {
            emissionDate = DateHelper.toDate(emissionDate, ['YYYY-MM-DD']);
        }
        return this.vatRates.find(function (vatRate) {
            return vatRate.isDateIn(emissionDate);
        }).rate;
    };
    return VatType;
}());
export { VatType };
