import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { TiimeAuthService } from 'tiime-auth';
import { MarkFormAsTouchedIfInvalid } from 'tiime-utils';
import { ChangePasswordForm } from './change-password-form';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(authService, cdr, router) {
        this.authService = authService;
        this.cdr = cdr;
        this.router = router;
        this.isMailSent = false;
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.initChangePasswordForm();
    };
    ChangePasswordComponent.prototype.submitChangePasswordForm = function () {
        this.changePassword();
    };
    ChangePasswordComponent.prototype.navigateToSignin = function () {
        this.router.navigateByUrl('/signin');
    };
    ChangePasswordComponent.prototype.initChangePasswordForm = function () {
        this.changePasswordForm = new ChangePasswordForm();
    };
    ChangePasswordComponent.prototype.changePassword = function () {
        var _this = this;
        this.authService
            .changePassword(this.changePasswordForm.email.value)
            .then(function () { return _this.changePasswordSuccess(); });
    };
    ChangePasswordComponent.prototype.changePasswordSuccess = function () {
        this.isMailSent = true;
        this.cdr.markForCheck();
    };
    tslib_1.__decorate([
        MarkFormAsTouchedIfInvalid('changePasswordForm'),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ChangePasswordComponent.prototype, "submitChangePasswordForm", null);
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
