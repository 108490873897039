import { ApplicationRef, enableProdMode, isDevMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
function enableDebugTools(moduleRef) {
    import('@angular/platform-browser').then(function (platformBrowser) {
        var applicationRef = moduleRef.injector.get(ApplicationRef);
        var componentRef = applicationRef.components[0];
        platformBrowser.enableDebugTools(componentRef);
    });
}
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function (moduleRef) {
    if (isDevMode()) {
        enableDebugTools(moduleRef);
    }
})
    .catch(function (err) { return console.log(err); });
