import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TiimeSnackbarService, SnackbarConfig } from 'tiime-material';
import { sharedInjector } from '../../shared/shared-injector';
export function ApiAlertError(statusBlackList) {
    if (statusBlackList === void 0) { statusBlackList = [403, 404]; }
    return function (target, propertyKey, descriptor) {
        var original = descriptor.value;
        descriptor.value = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return original.apply(this, args).pipe(catchError(function (error) {
                if (error instanceof HttpErrorResponse &&
                    error.error &&
                    error.error.error_description &&
                    !statusBlackList.includes(error.status)) {
                    sharedInjector
                        .get(TiimeSnackbarService)
                        .open(error.error.error_description, SnackbarConfig.error);
                }
                else if (error.error instanceof Blob &&
                    error.error.type === 'application/json') {
                    var reader_1 = new FileReader();
                    reader_1.onloadend = function () {
                        var fileError = JSON.parse(reader_1.result);
                        if (fileError.error_description) {
                            sharedInjector
                                .get(TiimeSnackbarService)
                                .open(fileError.error_description, SnackbarConfig.error);
                        }
                    };
                    reader_1.readAsText(error.error);
                }
                return throwError(error);
            }));
        };
        return descriptor;
    };
}
