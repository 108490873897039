import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { map, take, switchMap, catchError, tap } from 'rxjs/operators';
import * as BusinessUserActions from '../store/business-user/business-user-actions';
import { ApiService } from '../api.service';
import { businessUserSelector } from '../store/business-user/business-user-selector';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../api.service";
var BusinessUserGuard = /** @class */ (function () {
    function BusinessUserGuard(store, apiService) {
        this.store = store;
        this.apiService = apiService;
    }
    BusinessUserGuard.prototype.canActivate = function () {
        return this.hasBusinessUser();
    };
    BusinessUserGuard.prototype.canLoad = function () {
        return this.hasBusinessUser();
    };
    BusinessUserGuard.prototype.hasBusinessUserInStore = function () {
        return this.store.pipe(select(businessUserSelector), map(function (businessUser) { return !!businessUser; }), take(1));
    };
    BusinessUserGuard.prototype.hasBusinessUserInApi = function () {
        var _this = this;
        return this.apiService.getBusinessUser().pipe(tap(function (businessUser) {
            return _this.store.dispatch(new BusinessUserActions.UpdateAction(businessUser));
        }), catchError(function () { return of(false); }), switchMap(function () { return _this.hasBusinessUserInStore(); }));
    };
    BusinessUserGuard.prototype.hasBusinessUser = function () {
        var _this = this;
        return this.hasBusinessUserInStore().pipe(switchMap(function (inStore) {
            return inStore ? of(inStore) : _this.hasBusinessUserInApi();
        }));
    };
    BusinessUserGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessUserGuard_Factory() { return new BusinessUserGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.ApiService)); }, token: BusinessUserGuard, providedIn: "root" });
    return BusinessUserGuard;
}());
export { BusinessUserGuard };
