import { CompanyStatus } from '../enums/company-status.enum';
var CompanyBase = /** @class */ (function () {
    function CompanyBase(id, name, businessUnits, status, legalForm) {
        this.id = id;
        this.name = name;
        this.businessUnits = businessUnits;
        this.status = status;
        this.legalForm = legalForm;
    }
    Object.defineProperty(CompanyBase.prototype, "firstBusinessUnit", {
        get: function () {
            return this.businessUnits[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyBase.prototype, "isFreemium", {
        get: function () {
            return this.status === CompanyStatus.Freemium;
        },
        enumerable: true,
        configurable: true
    });
    return CompanyBase;
}());
export { CompanyBase };
