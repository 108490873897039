import * as tslib_1 from "tslib";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegexUtils } from 'tiime-utils';
var ChangePasswordForm = /** @class */ (function (_super) {
    tslib_1.__extends(ChangePasswordForm, _super);
    function ChangePasswordForm() {
        return _super.call(this, {
            email: new FormControl('', Validators.compose([
                Validators.pattern(RegexUtils.mailRegex),
                Validators.required
            ]))
        }) || this;
    }
    Object.defineProperty(ChangePasswordForm.prototype, "email", {
        get: function () {
            return this.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangePasswordForm.prototype, "emailErrorMessage", {
        get: function () {
            return (this.email.touched &&
                (this.email.hasError('required')
                    ? 'Email requis'
                    : this.email.hasError('pattern')
                        ? 'Email invalide'
                        : null));
        },
        enumerable: true,
        configurable: true
    });
    return ChangePasswordForm;
}(FormGroup));
export { ChangePasswordForm };
