import * as tslib_1 from "tslib";
import { BusinessUnit } from './business-unit';
import { CompanyBase } from './company-base';
import { CompanyCounter } from './company-counter';
import { CompanyReminder } from './company-reminder';
var CompanyListItem = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyListItem, _super);
    function CompanyListItem(id, name, businessUnits, status, legalForm, counters, reminders) {
        var _this = _super.call(this, id, name, businessUnits, status, legalForm) || this;
        _this.counters = counters;
        _this.reminders = reminders;
        return _this;
    }
    CompanyListItem.fromJson = function (json) {
        return new CompanyListItem(json.id, json.name, json.business_units
            ? json.business_units.map(function (businessUnitJson) {
                return BusinessUnit.fromJson(businessUnitJson);
            })
            : [], json.status, json.legal_form, json.counters
            ? json.counters.map(function (counterJson) {
                return CompanyCounter.fromJson(counterJson);
            })
            : [], json.reminders
            ? json.reminders.map(function (reminderJson) {
                return CompanyReminder.fromJson(reminderJson);
            })
            : []);
    };
    CompanyListItem.toJson = function (company) {
        return {
            id: company.id,
            name: company.name,
            business_units: company.businessUnits
                ? company.businessUnits.map(function (businessUnit) {
                    return BusinessUnit.toJson(businessUnit);
                })
                : [],
            status: company.status
        };
    };
    return CompanyListItem;
}(CompanyBase));
export { CompanyListItem };
