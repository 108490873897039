import { Overlay } from '@angular/cdk/overlay';
import { TIIME_DATE_FORMATS, TIIME_RIPPLE_GLOBAL_OPTIONS } from 'tiime-material';
export function scrollFactory(overlay) {
    return function () { return overlay.scrollStrategies.reposition(); };
}
var ɵ0 = TIIME_RIPPLE_GLOBAL_OPTIONS, ɵ1 = TIIME_DATE_FORMATS;
var MaterialModule = /** @class */ (function () {
    function MaterialModule() {
    }
    return MaterialModule;
}());
export { MaterialModule };
export { ɵ0, ɵ1 };
